const Initial_State={    
}
const glnReducer=(state=Initial_State,action) => {
    switch(action.type){
        case 'GET_GLN':
        return action.payload 
        default :
        return state;
    }
}
    
export default glnReducer;
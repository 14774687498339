import React, { Component } from "react";
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Loadable from 'react-loadable';
import Loader from './components/common/Loader';
import { LicenseManager } from "ag-grid-enterprise";
import { Helmet } from "react-helmet";

const AsyncHomeComponent = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ "./components/common/Home"),
  loading: () => <Loader />,
  modules: ['HomeChunk'],
});

const AsyncShipperAttributeComponent = Loadable({
  loader: () => import(/* webpackChunkName: "shipperattributeChunk" */ "./shipperDetails/ShipperAttributes"),
  loading: () => <Loader />,
  modules: ['shipperattributeChunk'],
});

const AsyncShipperMaintenanceComponent = Loadable({
  loader: () => import(/* webpackChunkName: "ShipperMaintenanceChunk" */ "./shipperDetails/ShipperMaintenance"),
  loading: () => <Loader />,
  modules: ['ShipperMaintenanceChunk'],
});

const AsyncDashboardComponent = Loadable({
  loader: () => import(/* webpackChunkName: "Dashboard" */ "./components/Dashboard/Dashboard"),
  loading: () => <Loader />,
  modules: ['dashboardChunk'],
});
const AsyncCpdaDashboardComponent = Loadable({
  loader: () => import(/* webpackChunkName: "CPDADashboard" */ "./cpda/PublicationDetails/CPDADashboard"),
  loading: () => <Loader />,
  modules: ['cpdadashboardChunk'],
});

const AsyncPendingItemCreationComponent = Loadable({
  loader: () => import(/* webpackChunkName: "PendingItemCreation" */ "./components/PendingItemCreation/PendingItemCreation"),
  loading: () => <Loader />,
  modules: ['pendingItemCreationChunk'],
});


const AsyncResolveMultipleMatchComponent = Loadable({
  loader: () => import(/* webpackChunkName: "ResolveMultipleMatch" */ "./components/ResolveMultipleMatch/ResolveMultipleMatch"),
  loading: () => <Loader />,
  modules: ['resolveMultipleMatchChunk'],
});

const AsyncPageNotFoundComponent = Loadable({
  loader: () => import(/* webpackChunkName: "PageNotFound" */ "./components/common/PageNotFound"),
  loading: () => <Loader />,
  modules: ['PageNotFoundChunk'],
});

const AsyncLandingPageComponent = Loadable({
  loader: () => import(/* webpackChunkName: "LandingPageChunk" */ "./components/common/LandingPage"),
  loading: () => <Loader />,
  modules: ['LandingPageChunk'],
})
const AsyncCPDAItemPageComponent = Loadable({
  loader: () => import(/* webpackChunkName: "LandingPageChunk" */ "./cpda/ItemPage/ItemPage"),
  loading: () => <Loader />,
  modules: ['LandingPageChunk'],
})

const AsyncCPDACicPageComponent = Loadable({
  loader: () => import(/* webpackChunkName: "LandingPageChunk" */ "./cpda/CicPage/CicPage"),
  loading: () => <Loader />,
  modules: ['LandingPageChunk'],
})

const AsyncCPDAPublicationHierarchyPageComponent = Loadable({
  loader: () => import(/* webpackChunkName: "LandingPageChunk" */ "./cpda/PublicationHierarchyPage/PublicationHierarchyPage"),
  loading: () => <Loader />,
  modules: ['LandingPageChunk'],
})

const AsyncShipperDashboardComponent = Loadable({
  loader: () => import(/* webpackChunkName: "ShipperDashboard" */ "./shipperDetails/ShipperDashboard"),
  loading: () => <Loader />,
  modules: ['shipperdashboardChunk'],
});

const AsyncShipperDetailsComponent = Loadable({
  loader: () => import(/* webpackChunkName: "ShipperDashboard" */ "./shipperDetails/ShipperDetails"),
  loading: () => <Loader />,
  modules: ['shipperdetailsChunk'],
});

const licenseKey = `${process.env.LICENSE_KEY}`;
console.log("LicenseManager : (" + licenseKey + ")");
LicenseManager.setLicenseKey('CompanyName=Walmart Inc.,LicensedGroup=Merchandising Development,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=45,LicensedProductionInstancesCount=0,AssetReference=AG-019169,ExpiryDate=19_November_2022_[v2]_MTY2ODgxNjAwMDAwMA==de098c35db6b39577172a33c89efff2a');

export class App extends Component {

  render() {
    return (
      <div className="App">
        <Routes>
          <Route index element={<AsyncHomeComponent />} />
          <Route path="/home" element={<AsyncHomeComponent />} />
          <Route path="/app/landing" element={<AsyncLandingPageComponent />} />
          <Route path="/app/itempage" element={<AsyncCPDAItemPageComponent />} />
          <Route path="/app/cicpage" element={<AsyncCPDACicPageComponent />} />
          <Route path="/app/dashboard" element={<AsyncDashboardComponent />} />
          <Route path="/app/cpdaDashboard" element={<AsyncCpdaDashboardComponent />} />
          <Route path="/app/publicationhierarchypage" element={<AsyncCPDAPublicationHierarchyPageComponent />} />
          <Route path="/app/pendingItem" element={<AsyncPendingItemCreationComponent />} />
          <Route path="/app/resolveMultiMatch" element={<AsyncResolveMultipleMatchComponent />} />
          <Route path='/invaliduser' element={<AsyncHomeComponent />} />
          <Route path='/app/shipperDashboard' element={<>
            <Helmet>
              <title>Shipper</title>
            </Helmet>
            <AsyncShipperAttributeComponent />
          </>
          }>
            <Route index element={<AsyncShipperDashboardComponent />} />
            <Route path="search" element={<AsyncShipperMaintenanceComponent />} />
            <Route path="shipperDetails" element={<AsyncShipperDetailsComponent />} />
          </Route>
          <Route path='*' element={<AsyncPageNotFoundComponent />} />
        </Routes>
      </div>
    );
  }
}

export default
  connect(
    null,
    null
  )(App);
const initialState = {
  cicData:{}
};

export const cpdaPublicationHierarchyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CPDA_PUBLICATION_HIERARCHY_DATA":
      return {
        ...state,
        publicationHierarchyData: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  cicData:{},
  cicSearchData: {
    assortmentId: "",
    itemNumber: "",
  },
};

export const cpdaCicReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CPDA_CIC_DATA":
      return {
        ...state,
        cicData: action.payload,
      };
      case "SET_CIC_SEARCH_CRITERIA":
          return {
        ...state,
        cicSearchData: action.payload,
      };          
    default:
      return state;
  }
};

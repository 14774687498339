import React from "react";
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import {BrowserRouter} from 'react-router-dom';
import { Provider as ReduxProvider } from "react-redux";

import App from "./App";
import configureStore from './redux/configureStore';

import "./App.css";
import "./index.css";
import "antd/dist/antd.css";
import "./common.css";

const store = configureStore( window.__REDUX_STATE__ || {} );

const AppBundle = (
  <ReduxProvider store={store}>
      <BrowserRouter>
          <App />
      </BrowserRouter>
  </ReduxProvider>
);
const container = document.getElementById('root');
window.onload = () => {
Loadable.preloadReady().then(() => {
      ReactDOM.hydrate(          
          AppBundle,
          container
      );
  });
};

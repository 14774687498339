import {combineReducers} from 'redux'
import glnReducer from './glnReducers'
import brickReducer from './brickReducers'
import svalReducer from './svalReducers'
import { appReducer } from './ssrReducer';
import {cpdaPublishDetailsReducer} from './cpdaPublishDetailsReducer';
import {cpdaItemReducer} from './cpdaItemReducer';
import {cpdaCicReducer} from "./cpdaCicReducer";
import {cpdaPublicationHierarchyReducer} from "./cpdaPublicationHierarchyReducer";
import {shipperMaintenanceReducer} from "./shipperMaintenanceReducer";
export default combineReducers({
    GLN:glnReducer,
    PTYPE:brickReducer,    
    SVAL:svalReducer,
    app: appReducer,
    cpdaDashboard: cpdaPublishDetailsReducer,
    cpdaItem:cpdaItemReducer,
    cpdaPublicationHierarchy:cpdaPublicationHierarchyReducer,
    cpdaCic:cpdaCicReducer,
    shipperMaintenance:shipperMaintenanceReducer
})


const initialState = {
  itemData: {},
  itemSearchData: {
    assortmentId: "",
    itemNumber: "",
  },
};

export const cpdaItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CPDA_ITEM_DATA":
      return {
        ...state,
        itemData: action.payload,
      };
    case "SET_ITEM_SEARCH_CRITERIA":
      return {
        ...state,
        itemSearchData: action.payload,
      };

    default:
      return state;
  }
};

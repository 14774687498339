const initialState = {
  appName: "tas",
  cpdaData:{},
  cpdaSupplierList:{},
  dashboardData:{},
  isLoading:false,
  selectedSearchCriteria:{
     selectedGTIN: null,
    selectedIPGLN: null,
    selectedAssortment: null,
    selectedAssortmentId: null,
    selectedItemNumber: null,
    selectedipGlnName: null,
    selectedSupplier: null,
    selectedGlobalClassificaiton: null,
    selectedPublicationType: null,
    selectedPublicationSyncStatus: null,
    selectedItemSyncStatus: null,
    selectedPublicationCicState: null,
    selectedHierarchy: null,
    selectedItemCICStatus: null,
    selectedFromDate: null,
    selectedToDate: null,
    selectedDateRange: null,
  },
  selectedRow:{}
};

export const cpdaPublishDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_APPNAME":
      return {
        ...state,
        appName: action.payload,
      };
    case "SET_CPDA_DATA":
      return {
        ...state,
        cpdaData: action.payload,
      };
    case "SET_PUBLICATION_DETAILS_VIEW_DATA":
       return {
        ...state,
        dashboardData: action.payload,
      };
       case "SET_CPDA_DASHBOARD_SEARCH_DATA":
       return {
        ...state,
        selectedSearchCriteria: action.payload,
      };
       case "SET_LOADING":
       return {
        ...state,
        isLoading: action.payload,
      };
       case "SET_SELECTED_PUBLICATION_ROW":
       return {
        ...state,
        selectedRow: action.payload,
      };
    default:
      console.log("WARN: cpdaPublishDetailsReducer NO MATCH FOUND for action.type (" + action.type + ")")
      return state;
  }
};
const initialState = {
  shipperMaintenanceData: {},
  isLoading: false,
  selectedShipperSearchCriteria: {
    selectedGTIN: null,
    selectedIPGLN: null,
    selectedAssortment: null,
    selectedAssortmentId: null,
    selectedItemNumber: null,
    selectedipGlnName: null,
    selectedSupplier: null,
    selectedGlobalClassificaiton: null,
    selectedPublicationType: null,
    selectedPublicationSyncStatus: null,
    selectedItemSyncStatus: null,
    selectedPublicationCicState: null,
    selectedHierarchy: null,
    selectedItemCICStatus: null,
    selectedFromDate: null,
    selectedToDate: null,
    selectedDateRange: null,
    selectedVariantItemNumber: null,
    selectedalternativeItemIdentification: null,
    selectedshipperEffectiveDate: null,
    selectedpackagingLevelProductType: null,
    selectedshipperDescription: null,
    selectedbrandOwnerVendorNumber: null,
    selectedreplenishmentType: null,
    selecteddistributorVendorNumber: null,
    selectedinformationProviderVendorNumber: null,
    selectedmanufacturerVendorNumber: null,
    selecteddepth: null,
    selectedwidth: null,
    selectedheight: null,
    selectedweight: null,
    selectedti: null,
    selectedhi: null,
  }
};

export const shipperMaintenanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MAINTENANCE_DATA":
      return {
        ...state,
        maintenanceData: action.payload,
      };
    case "EDIT_MAINTENANCE_DATA":
      return {
        ...state,
        editMaintenanceData: action.payload,
      };
    case "ISDIRTY":
      return {
        ...state,
        isDirtyLoad: action.payload,
      }; 
    case "ISDIRTYCREATESHIPPER":
      return {
        ...state,
        isDirtyCreateShipperLoad: action.payload,
      };
    case "ISSUPERUSERROLE":
      return {
        ...state,
        isSuperUserRole: action.payload,
      };
    case "UPDATECONTENTITEMREFRESH":
      return {
        ...state,
        updateContentItemSpread: action.payload,
      };
    case "SET_CONTENTITEM_SEARCH_DATA":
      return {
        ...state,
        selectedShipperSearchCriteria: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_SELECTED_CONTENT_ITEM":
      return {
        ...state,
        selectedItem: action.payload,
      };
    case "SET_UNSELECTED_CONTENT_ITEM":
      return {
        ...state,
        unSelectedItem: action.payload,
      };
    case "SET_SHIPPER_CREATION":
      return {
        ...state,
        shipperResponse: action.payload,
      };
    case "SET_SHIPPER_FAILURE":
      return {
        ...state,
        shipperFailureResponse: action.payload,
      };
    case "SET_CONTENTITEM_DETAILS_VIEW_DATA":
      return {
        ...state,
        shipperdashboardData: action.payload,
      };
    default:
      console.log("WARN: Shipper Related Reducer NO MATCH FOUND for action.type (" + action.type + ")")
      return state;
  }
};

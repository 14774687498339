import moment from 'moment'

const Initial_State = {
    PIR: [],
    RMM: [],
    OQM: [],
    selectedDate: "7 Days",
    selectedSno: [],
    selectedGLN: [],
    selectedPtype: [],
    selectedFromDate: moment(new Date(new Date().setDate(new Date().getDate() - 6))),
    selectedToDate: moment(new Date()),
    selectedUser: {
        firstName: "",
        lastName: "",
        emailId: "",
        isExternal: '',
        loginId: '',
        rlId: '',
        partners: [],
        vendorId: '',
        partnerType: '',
        authorized: ""
    },
    showLoader: false
}
const svalReducer = (state = Initial_State, action) => {
    switch (action.type) {
        case 'USER': {
            return {
                ...state,
                selectedUser: action.payload,
            };
        }

        case 'S_GLN':
            return Object.assign({}, state, action.payload);
        case 'S_SUP':
            return Object.assign({}, state, action.payload);
        case 'P_TYPE':
            return Object.assign({}, state, action.payload);
        case 'GET_COUNT':
            return Object.assign({}, state, action.payload);
        case 'S_DATE':
            return {...state, ...action.payload}
        case 'SHOW_LOADER':
            return Object.assign({}, state, action.payload)
        default :
            return state;
    }
}
export default svalReducer;